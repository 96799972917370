import Vue from 'vue'
import VueRouter from 'vue-router'
// 首页
import Home from '../views/home/Home.vue'
// 登录页
const Login = () => import('@/views/login/Login')
//注册页
const Register = () => import('@/views/register/Register')
//港澳台及境外注册
const RegisterHMT= () => import('@/views/register/RegisterHMT')

// 注册选择
const RegisterOpt = () => import('@/views/register/RegisterOpt')
// 首页
const Dashboard = () => import('@/views/index/Dashboard')
// 报名规则详情
const RuleDetails= () => import('@/views/index/RuleDetails')
// 电子准考证
const ElectricCard= () => import('@/views/onLine/ElectricCard')
Vue.use(VueRouter)

// 解决从“/”到“/dashboard”的导航被取消，使用一个新的导航的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/', //登录页
        component: Login,
        hidden: true
    },
    {
        path: '/login', //登录页
        component: Login,
        hidden: true
    },
    {
        name: "register", //注册
        component: Register,
        path: '/register',
    },{
        name: "registerHMT", //港澳台及境外注册
        component: RegisterHMT,
        path: '/registerHMT',
    },
    {
        name: "registerOpt", //注册选择
        component: RegisterOpt,
        path: '/registerOpt',
    },
    {
        name: "electricCard", //电子准考证
        component: ElectricCard,
        path: '/electricCard',
    },
    {
        name: "newpage", //用于跳转刷新
        path: '/home/newpage',
        component: () => import('@/views/Emptypage/Newpage')
    },
    {
        path: '/',
        component: Home,
        redirect: "/dashboard",
        children:[
            {
                path: "/dashboard",
                component: Dashboard,
                meta: {
                    title: '首页', //首页
                }
            },
            {
                path: "/ruleDetails",
                component: RuleDetails,
                meta: {
                    title: '报名规则详情', 
                }
            },
            {
                path: "/signApply",
                component: () => import('@/views/onLine/SignApply'),
                alwaysShow: true,
                name: "signApply",
                meta: {
                    title: '报名申请',
                    noCache: true,
                },
                children: []
            },
            {
                path: "/signRecord",
                component: () => import('@/views/onLine/SignRecord'),
                alwaysShow: true,
                name: "signRecord",
                meta: {
                    title: '报名记录(准考证)',
                    noCache: true,
                    keepAlive: true // 该路由会被缓存
                },
                children: []
            },
            {
                path: "/signDraft",
                component: () => import('@/views/onLine/SignDraft'),
                alwaysShow: true,
                name: "signDraft",
                meta: {
                    title: '报名记录(准考证)--草稿',
                    noCache: false,
                },
                children: []
            },{
                path: "/signState",
                component: () => import('@/views/onLine/SignState'),
                alwaysShow: true,
                name: "signState",
                meta: {
                    title: '报名记录(准考证)--报名',
                    noCache: false,
                },
                children: []
            },
            {
                path: "/signGrant",
                component: () => import('@/views/onLine/SignGrant'),
                alwaysShow: true,
                name: "signGrant",
                meta: {
                    title: '报名记录(准考证)--准考',
                    noCache: false,
                },
                children: []
            },
            {
                path: "/seeGrant",
                component: () => import('@/views/onLine/SeeGrant'),
                alwaysShow: true,
                name: "seeGrant",
                meta: {
                    title: '查看准考证',
                    noCache: false,
                },
                children: []
            },
            {
                path: "/scoreQuery",
                component: () => import('@/views/scoreQuery/ScoreQuery'),
                alwaysShow: true,
                name: "scoreQuery",
                meta: {
                    title: '成绩查询',
                    noCache: true,
                    keepAlive: true // 该路由会被缓存
                },
                children: []
            },
            {
                path: "/scoreDetails",
                component: () => import('@/views/scoreQuery/ScoreDetails'),
                alwaysShow: true,
                name: "scoreDetails",
                meta: {
                    title: '成绩详情',
                    noCache: true,
                },
                children: []
            },
            {
                path: "/msgList",
                component: () => import('@/views/examineeMsg/MsgList'),
                alwaysShow: true,
                name: "msgList",
                meta: {
                    title: '考生留言列表',
                    noCache: true,
                    keepAlive: true // 该路由会被缓存
                },
                children: []
            },
            {
                path: "/examineeMsg",
                component: () => import('@/views/examineeMsg/ExamineeMsg'),
                alwaysShow: true,
                name: "examineeMsg",
                meta: {
                    title: '考生留言新增',
                    noCache: false,
                },
                children: []
            },
            {
                path: "/personalData",
                component: () => import('@/views/personalData/PersonalData'),
                alwaysShow: true,
                name: "personalData",
                meta: {
                    title: '个人资料',
                    noCache: true,
                },
                children: []
            },
            
        ],
        
}]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
