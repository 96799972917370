import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Cookies from 'js-cookie'

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
Vue.use(ElementUI);
// 请求
import $requst from './utils/Http.js'
Vue.prototype.$request = $requst

Vue.prototype.$cookies = Cookies


// 拼音转换
import pinyin from '@/utils/Convert_Pinyin.js'
Vue.prototype.$pinyin = pinyin
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')


let arr = ['/', '/login', '/register','/registerOpt','/registerHMT']

router.beforeEach((to, from, next) => {
    // console.log(!localStorage.getItem('token')==false)
    // console.log(to)
    if (localStorage.getItem('token') || arr.includes(to.fullPath)) {
        next()
    } else {
        ElementUI.Message({
            message: '您还未登录,请先登录!',
            type: 'error',
            duration: 1500,
            offset: 100
        })
        next('/login')
    }
})
