<!-- 首页 -->
<template>
    <div class="home">
        <el-container>
            <el-aside :class="isCollapse?'scrollbar':'not-text'">
                <el-scrollbar :class="isCollapse?'scrollbar':''">
                    <img :src='logoimg' class="img">
                    <!-- 头像与名字 -->
                    <!-- <div class="headImg-name">
                        <div class="headImg">
                            <img :src="headImg" >
                        </div>
                        <div class="name">
                            {{name}}
                        </div>
                    </div> -->

                    <!-- 菜单 -->
                    <el-menu :default-active="onRouts" class="el-menu-vertical-demo" @open="handleOpen"
                        @close="handleClose" background-color="#29726C" text-color="#fff" router :collapse="isCollapse"
                        active-text-color="#ffffff" :collapse-transition="false" :default-openeds="openeds"
                        :class="isCollapse?'scrollbar':''">
                        <el-menu-item index="/dashboard">
                            <img src="../../assets/home.png" style="padding: 0px 10px 0px 5px;">
                            <span>首页</span>
                        </el-menu-item>
                        <el-submenu index='1'>
                            <template slot="title">
                                <img src="../../assets/sign.png" style="padding: 0px 10px 0px 5px;">
                                <span>在线报名</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item index="/signApply">报名申请</el-menu-item>
                                <el-menu-item index="/signRecord">报名记录(准考证)</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                        <el-menu-item index="/scoreQuery">
                            <img src="../../assets/query.png" style="padding: 0px 10px 0px 5px;">
                            <span>成绩查询</span>
                        </el-menu-item>
                        <!--                       <el-menu-item  index="/examineeMsg" >
                            <img src="../../assets/liuyan.png" style="padding: 0px 10px 0px 5px;">
                            <span>考生留言新增</span>
                        </el-menu-item> -->
                        <el-menu-item index="/msgList">
                            <img src="../../assets/liuyan.png" style="padding: 0px 10px 0px 5px;">
                            <span>考生留言</span>
                        </el-menu-item>
                        <el-menu-item index="/personalData">
                            <img src="../../assets/personal.png" style="padding: 0px 10px 0px 5px;">
                            <span>个人资料</span>
                        </el-menu-item>
                        <el-menu-item @click="outLogin">
                            <img src="../../assets/backOut.png" style="padding: 0px 10px 0px 5px;">
                            <span>安全退出</span>
                        </el-menu-item>
                    </el-menu>
                </el-scrollbar>
            </el-aside>
            <!-- 右边 -->
            <el-main :style="{width:(fullWidth-230)+'px'}">
                <el-container>
                    <el-header
                        style="display: flex;justify-content: space-between;flex-wrap: wrap;position: relative;z-index: 5;">
                        <div class="" style='height: 50px; display: flex;align-items: center;'>
                            <span class="span_1" :class="rotate?'cartoon':'timing'" style="display: flex;align-items: center;
                            justify-content: center;" @click="toggleClick()">
                                <img src="../../assets/menuIcon.png">
                            </span>
                            <div class="">
                                考级报名系统
                            </div>
                        </div>
                        <!-- 下拉部分 -->
                        <div class="t-right">
                            <img src="../../assets/rightImg.png">
                            <div class="" style="cursor: pointer;padding:0px 10px;">
                                <!-- 書畫藝術學院 -->
                                {{name}}
                            </div>
                            <!-- 下拉菜单 -->
                            <el-dropdown trigger="click">
                                <span class="el-dropdown-link">
                                    <i class="el-icon-arrow-down"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" style="padding: 0px;">
                                    <!-- <el-dropdown-item class="personal"
                                        @click.native="personal">个人信息</el-dropdown-item>
                                    <el-dropdown-item class="modify">修改密码</el-dropdown-item> -->
                                    <el-dropdown-item class='quit' @click.native="outLogin">退出系统</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </el-header>
                    <el-main class='abc'>

                        <router-view></router-view>
                    </el-main>
                </el-container>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                openeds: ["1"],
                fullWidth: document.documentElement.clientWidth, //屏幕宽度
                isCollapse: true, //判断是否展开关闭
                rotate: true, //判断是否旋转
                logoimg: require("../../assets/logo1@2x.png"),
                headImg: require('../../assets/logo.png'), //头像
                name: "王某某", //名字
                nav: [{
                    id: "1",
                    name: "首页",
                    // icon: require('../../assets/home.png'),
                    path: "/dashboard",
                    noCache: true,
                    listGroup: [{
                        id: "1-1",
                        name: "报名规则详情",
                        path: "/ruleDetails",
                        noCache: true,
                    }]
                }, {
                    id: "2",
                    name: "在线报名",
                    // icon: require('../../assets/home.png'),
                    // path: "/signApply",
                    noCache: true,
                    listGroup: [{
                            id: "2-1",
                            name: "报名申请",
                            path: "/signApply",
                            noCache: true,
                        },
                        {
                            id: "2-2",
                            name: "报名记录(准考证)",
                            path: "/signRecord",
                            noCache: true,
                        },
                        {
                            id: "2-3",
                            name: "报名记录(准考证)--草稿",
                            path: "/signDraft",
                            noCache: false,
                        }, ,
                        {
                            id: "2-4",
                            name: "报名记录(准考证)--报名",
                            path: "/signState",
                            noCache: false,
                        },
                        {
                            id: "2-5",
                            name: "报名记录(准考证)--准考",
                            path: "/signGrant",
                            noCache: false,
                        },
                        {
                            id: "2-6",
                            name: "报名记录(准考证)--查看准考证",
                            path: "/seeGrant",
                            noCache: false,
                        },

                    ]
                }, {
                    id: "3",
                    name: "成绩查询",
                    // icon: require('../../assets/home.png'),
                    path: "/scoreQuery",
                    noCache: true,
                    listGroup: []
                }, {
                    id: "4",
                    name: "成绩详情",
                    // icon: require('../../assets/home.png'),
                    path: "/scoreDetails",
                    noCache: true,
                    listGroup: []
                }, {
                    id: "5",
                    name: "考生留言列表",
                    // icon: require('../../assets/home.png'),
                    path: "/msgList",
                    noCache: true,
                    listGroup: []
                }, {
                    id: "6",
                    name: "考生留言新增",
                    // icon: require('../../assets/home.png'),
                    path: "/examineeMsg",
                    noCache: false,
                    listGroup: []
                }, {
                    id: "7",
                    name: "个人资料",
                    // icon: require('../../assets/home.png'),
                    path: "/personalData",
                    noCache: true,
                    listGroup: []
                }],
                info: {}
            }
        },
        created() {
            this.info = JSON.parse(localStorage.getItem("info"))
            // console.log(this.info)
            this.name = this.info.username
            // this.headImg=this.info.head_image
        },
        mounted() {
            // 监听屏幕变化改变布尔值
            window.onresize = () => {
                // console.log(document.documentElement.clientWidth)
                if (document.documentElement.clientWidth <= 768 || document.documentElement.clientWidth <= 1024) {
                    this.isCollapse = false
                }
            }
        },
        methods: {
            handleOpen(key, keyPath) {
                console.log(key, keyPath);

            },
            handleClose(key, keyPath) {
                // console.log(key, keyPath);
            },
            toggleClick() { //点击图片旋转 左侧菜单弹入弹出
                this.isCollapse = !this.isCollapse
                this.rotate = !this.rotate
            },
            outLogin() { //退出登录
                this.$message({
                    message: '退出成功',
                    type: 'success'
                })
                this.$router.replace({
                    path: "/login"
                })
                localStorage.removeItem("token")
                localStorage.removeItem("info")
                // this.$request({url: '/api/studentexam/logout', method: 'POST', data: {},

                // }).then(res => {
                //     console.log(res)
                // 	if(res.code == 1){
                // 		this.$message({
                // 		  message: '退出成功',
                // 		  type: 'success'
                // 		})
                // 		this.$router.replace({
                // 		    path: "/"
                // 		})
                //      sessionStorage.removeItem("info");
                // 	}else{
                //        this.$message({
                //          message: '退出失败',
                //          type: 'error'
                //        })  
                //     }
                // })
            }
        },
        computed: {
            onRouts() {
                return this.$route.path
            },
        }
    }
</script>

<style scoped lang="scss">
    .home {
        width: 100%;
        height: 100%;
    }

    .el-container {
        width: 100%;
        height: 100%;
    }

    /* 左边 */
    .el-aside {
        background-color: #29726C;
        color: #333;
        text-align: center;
        width: 230px !important;
        transition: all .5s;
        /* line-height: 200px; */
    }

    .abc {
        // border: 1px solid red;
        margin: 10px 20px;
        background-color: #FFFFFF !important;
        // min-width: 1200px;
        // height: 100vh;
        box-sizing: border-box;
    }
    // @media screen and (max-width: 768px) {
    //     .abc {
    //         // border: 1px solid red;
    //         margin: 10px 20px;
    //         background-color: #FFFFFF !important;
    //          min-width:calc(100% - 60px - 20px);
            
    //         height: 100vh;
    //          box-sizing: border-box;
    //          overflow-x: hidden;
    //     }
        
    // }
    /* logo */
    .img {
        width: 189px;
        height: 97px;
        margin-top: 22px;
        margin-bottom: 40px;
    }

    // 头像 名称
    .headImg-name {
        width: 100%;
        height: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 34px 0px;
    }

    .el-scrollbar {
        height: 100%;
        width: 230px !important;
        background-color: #29726C;
        text-align: center;
        transition: all .5s;
    }

    // 头像
    .headImg {
        width: 94px;
        height: 94px;
        border-radius: 50%;
        background-color: #fff;

    }

    .headImg img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    // 名字
    .name {
        margin: 5px 0px;
        color: #fff;
    }

    // 下拉菜单
    .el-menu {
        background-color: #304156;
        text-align: left;
        width: 230px !important;
        line-height: 200px !important;
        border-right: 0px !important;
        overflow: hidden;
        transition: all .5s;
    }

    .el-menu-item {
        width: 230px !important;
        // margin-left: 20px;
        text-align: left;
        height: 40px !important;
        line-height: 40px !important;
        background-color: #29726C !important;

    }

    .el-menu-item:hover {
        background-color: #18BC9C !important;
    }

    ::v-deep .el-menu-item-group__title {
        padding: 0px;
    }

    // 子菜单
    ::v-deep .el-submenu__title {
        width: 230px !important;
        text-align: left !important;
        height: 40px !important;
        line-height: 40px !important;
    }

    ::v-deep .el-submenu__title:hover {
        background-color: #18BC9C !important;
    }

    ::v-deep .el-menu-item-group .el-menu-item {
        padding-left: 50px !important;
        margin-left: 20px !important;
        width: 210px !important;

    }

    .el-menu-item-group {
        // background-color: #0C5650;
    }

    .el-menu-item.is-active {
        background: #18BC9C !important;
    }

    ::v-deep .el-submenu__icon-arrow.el-icon-arrow-down {
        color: #FFFFFF;
        font-size: 15px;
        font-weight: bold;
    }

    .scrollbar {
        width: 60px !important;
        transition: all .5s;
    }

    .not-text {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .timing {
        transition: all 0.5s;
    }

    //动画
    .cartoon img {
        transform: rotate(-90deg);
        transition: all 0.5s;
    }

    // 右边
    .el-main {
        background-color: #F0F3F5;
        color: #333;
        padding: 0px;
    }

    .el-header {
        background-color: #fff;
        color: #333;
        height: 50px !important;
        text-align: center;
        line-height: 50px;
        padding: 0px !important;
    }

    .span_1 {
        height: 50px;
        width: 50px;
        text-align: center;
    }

    .span_1:hover {
        cursor: pointer;
    }

    .t-right {
        display: flex;
        align-items: center;
        margin-right: 20px;
        color: #666;
    }

    .t-right img {
        cursor: pointer;
    }

    .t-right i {
        vertical-align: middle;
        font-size: 20px;
        // padding: 0px 5px;
     	  cursor: pointer;
    }

    ::v-deep .el-dropdown-menu__item:focus,
    .el-dropdown-menu__item:not(.is-disabled):hover {
        background-color: #efefef;
        color: #333;
    }

    // 退出
    .quit {
        padding-left: 30px;
        background: url(../../assets/tuichu.png) 8px 10px no-repeat;
    }
</style>
